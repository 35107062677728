import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BrandHeader from "../../components/brand-headers/durangoBrandHeader"
import BrandBody from "../../components/brand-bodies/durangoBrandBody"

const DurangoBootsPage = ({ pageContext, location }) => {
  const pageTitle = "Durango Boots"

  return (
    <>
      <Layout>
        <Seo title={pageTitle} />
        <BrandHeader />
        <BrandBody />
      </Layout>
      {/* description for search, hidden from page */}
      <p
        data-typesense-field="description"
        style={{ display: `none` }}
        aria-hidden="true"
      >
        "For over 50 years, Durango has been crafting authentic western footwear
        inspired by the adventure and spirit of the Old West. Durango traces its
        roots all the way back to 1966, when original parent company, Georgia
        Boot, envisioned a western inspired collection to complement its line of
        work boots. While Durango flourished as a western boot brand for the
        next 30 years, it also found a niche audience with motorcycle
        enthusiasts during the early 1990s. By the mid-1990s, Durango began
        appearing in magazines and television with greater frequently thanks to
        the popularity of the brand in the urban and grunge music scenes due the
        growth of country music, line dancing and western fashion. In 2002,
        Durango returned to its roots and renewed its focus on producing
        traditional western and farm & ranch footwear. Durango continues to
        innovate in the work western market along with its harness boot and
        fashion western styles. Today, Durango is more than a signature pair of
        boots, it’s a lifestyle. Deep-rooted in the soul of country music with a
        long tradition of fashionable western style, Durango Boots are
        authentically Americana. Classic yet modern, confident yet relaxed, they
        make a statement in country cool style. Whether you are ready to work
        hard or play hard, you can kick up your heels and celebrate freedom.
        Harness your western spirit by slipping on a pair of Durango Boots."
      </p>
    </>
  )
}

export default DurangoBootsPage

//head element
export const Head = () => (
  <>
    <title data-typesense-field="title">Durango Boots</title>
    <meta
      name="description"
      content="For over 50 years, Durango has been crafting authentic western footwear
      inspired by the adventure and spirit of the Old West. Durango traces its
      roots all the way back to 1966, when original parent company, Georgia
      Boot, envisioned a western inspired collection to complement its line of
      work boots. While Durango flourished as a western boot brand for the
      next 30 years, it also found a niche audience with motorcycle
      enthusiasts during the early 1990s. By the mid-1990s, Durango began
      appearing in magazines and television with greater frequently thanks to
      the popularity of the brand in the urban and grunge music scenes due the
      growth of country music, line dancing and western fashion. In 2002,
      Durango returned to its roots and renewed its focus on producing
      traditional western and farm & ranch footwear. Durango continues to
      innovate in the work western market along with its harness boot and
      fashion western styles. Today, Durango is more than a signature pair of
      boots, it’s a lifestyle. Deep-rooted in the soul of country music with a
      long tradition of fashionable western style, Durango Boots are
      authentically Americana. Classic yet modern, confident yet relaxed, they
      make a statement in country cool style. Whether you are ready to work
      hard or play hard, you can kick up your heels and celebrate freedom.
      Harness your western spirit by slipping on a pair of Durango Boots."
    />
  </>
)
