import React from "react"
import PropTypes from "prop-types"
import { Link, graphql, useStaticQuery } from "gatsby"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import * as brandHeaderStyles from "./BrandHeader.module.scss"

const BrandHeader = ({}) => {
  return (
    <div className={brandHeaderStyles.headerDurango}>
      <div className={brandHeaderStyles.wrapper}>
        <div className={brandHeaderStyles.heroImageWrapper} id="brand-header-hero">
          <StaticImage
            src="../../assets/images/index/brand-headers/durango/durango-header-mobile.jpg"
            className={brandHeaderStyles.heroImageMB}
            alt=""
          />
          <StaticImage
            src="../../assets/images/index/brand-headers/durango/durango-header-tablet.jpg"
            className={brandHeaderStyles.heroImageTB}
            alt=""
          />
          <StaticImage
            src="../../assets/images/index/brand-headers/durango/durango-header-desktop.jpg"
            className={brandHeaderStyles.heroImageDT}
            alt=""
          />
        </div>
        <div className={`brand-container ${brandHeaderStyles.title}`}>
          {/* just a placeholder til dynamic breadcrumb component is built */}
          <h1>Durango&reg;</h1>
          <p>Building authentic western boots with a heritage of quality since 1966.</p>
          <div className={brandHeaderStyles.buttons}>
            <a class="CTAblack" href="https://www.durangoboots.com/Home" title="Durango Boots">Visit Website</a>
            {/* <a class="CTAalt" href="" title="">Watch Brand Video</a> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BrandHeader
