import React from "react"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import * as brandBodyStyles from "./BrandBody.module.scss"
import { Link } from "gatsby"

const BrandBody = ({}) => {
  return (
    <div className={brandBodyStyles.brandDurango}>
      <div className={brandBodyStyles.brandBody}>
        <div className={brandBodyStyles.about}>
          <div className={brandBodyStyles.info}>
            <h2>About Durango&reg;</h2>
            <p>For over half a century, Durango&reg; has been building the world's best authentic western footwear merging style, comfort, and innovation for those who live the western life.</p>
            <p>Whether that be ranching, roping, or riding, Durango&reg; is committed to western tradition by building lightweight and durable boots that embody dependable timeless craftsmanship and trusted value.</p>
            <p>Durango&reg; is redefining how comfortable work and western boots can be for those who work sunup to sundown. At Durango&reg;, We Ride for The Brand. We're proud of who we are and the boots we build. We honor the cowboy way and proudly live our western values.</p>
            <p>Durango&reg; Boots are made for what you do. This is Durango&reg; Country.</p>
          </div>
          <div className={brandBodyStyles.rightImage}>
            <StaticImage
              src="../../assets/images/index/brand-body/durango/durango-crew-right.jpg"
              className={brandBodyStyles.halfImage}
              alt="Durango Family"
              imgStyle={{width: '100%;' , maxWidth: `100%`, maxHeight: `100%`}}
              style={{width: '100%' , maxWidth: `100%`, maxHeight: `100%`}}
            />
          </div>
        </div>
        <div className={brandBodyStyles.highlightSection}>
          <div className={brandBodyStyles.imageGrid}>
              <StaticImage
                  src="../../assets/images/index/brand-body/durango/durango-square-02.jpg"
                  className={brandBodyStyles.one}
                  alt=""
                  imgStyle={{width: `100%`, maxWidth: `100%`, maxheight: `100%`}}
                  style={{width: `100%`, maxWidth: `100%`, maxheight: `100%`}}
                />
                <StaticImage
                  src="../../assets/images/index/brand-body/durango/durango-square-01.jpg"
                  className={brandBodyStyles.two}
                  alt=""
                  imgStyle={{width: `100%`, maxWidth: `100%`, maxHeight: `100%`}}
                  style={{width: `100%`, maxWidth: `100%`, maxHeight: `100%`}}
                />
                <StaticImage
                  src="../../assets/images/index/brand-body/durango/durango-square-04.jpg"
                  className={brandBodyStyles.three}
                  alt=""
                  imgStyle={{width: `100%`, maxWidth: `100%`, maxHeight: `100%`}}
                  style={{width: `100%`, maxWidth: `100%`, maxHeight: `100%`}}
                />
                <StaticImage
                  src="../../assets/images/index/brand-body/durango/durango-square-03.jpg"
                  className={brandBodyStyles.four}
                  alt=""
                  imgStyle={{width: `100%`, maxWidth: `100%`, maxHeight: `100%`}}
                  style={{width: `100%`, maxWidth: `100%`, maxHeight: `100%`}}
                />
            </div>
            <div className={brandBodyStyles.highlights}>
              <h3>Highlights</h3>
              
              <h4>PRCA Collection by Durango</h4>
              <p>Durango&reg; Boots is elated to join forces with the <a href="https://prorodeo.com/" title="Professional Rodeo Cowboys Association">Professional Rodeo Cowboys Association</a> to launch the brand new <a href="https://www.durangoboots.com/prca-collection/" title="PRCA Boot Collection">PRCA Boot collection</a>. This collection delivers top hand western boots engineered for rodeo performance, hand-crafted with superior comfort, premium leathers, and a low ground feel approved by world champions. The PRCA is the unsurpassed leader in sanctioning the sport of professional rodeo by providing the best cowboys, the best cowgirls, the best rodeos and now the best footwear with Durango&reg; Boots. The PRCA Collection by Durango&reg; is WHAT WINNERS WEAR.</p>
              
              <h4>Official Boot of NFR&trade;</h4>
              <p>Durango&reg; is proud to be the Official Boot of the <a href="http://www.nfrexperience.com/" title="Wrangler National Finals Rodeo">Wrangler National Finals Rodeo</a>&reg; (NFR&reg;) and a National Sponsor of <a href="https://prorodeo.com/" title="ProRodeo">ProRodeo</a>&reg;. The Wrangler NFR&reg;, is the season-ending championship event for the PRCA and is widely acknowledged to be the “Superbowl of Rodeo”. Held annually since 1959, the Wrangler NFR&reg; is ProRodeo's richest and most prestigious rodeo, showcasing the best rodeo athletes in the world. As the official Boot of the <a href="https://www.nfrexperience.com/" title="NFR">NFR</a>, Durango&reg; is committed to providing the most elite footwear on the market and remains committed to supporting the rodeo industry and athletes, not only through events, but through our robust research and development to create and build boots that support athletes and fans alike.</p>
              
              <h4>Team Durango&reg;</h4>
              <p>Durango's team of professional rodeo athletes are comprised of male and female PRCA athletes who are world champions across various disciplines of Team Ropers, Saddle Bronc Riders, Bareback Riders, Barrel Racers, Steer Wrestlers, Breakaway Ropers, Tie-Down Ropers and Rodeo Announcers. To learn more about the team, visit <a href="https://www.durangoboots.com/team/" title="Team Durango">Team Durango&reg; | Durango Boots</a>.</p>
              
              <h4>National Day of the Cowgirl</h4>
              <p><a href="https://nationaldayofthecowgirl.com/" title="National Day of the Cowgirl">National Day of the Cowgirl</a> presented by Durango&reg; Boots occurs each year on September 15th and is a celebration and recognition of the spirit of the American cowgirl &das a spirit embodied by women who are strong, confident, and resilient. Whether through farming and ranching, arts and culture or equine sports, the spirit of a cowgirl shines through women from diverse backgrounds who share a passion for the Western way of life. This holiday serves as an opportunity to celebrate the authenticity and diversity of the American cowgirl, educate the public on the important contributions Western women make toward society and encourage women everywhere to adopt the strength, confidence and resiliency embodied by the cowgirl lifestyle.</p>
              
              <h4>Durango&reg; Rebel Pro&trade;</h4>
              <p><a href="https://www.durangoboots.com/mens-rebel-pro/" title="Durango Rebel Pro">Durango&reg; Rebel Pro<sup>&trade;</sup></a> boots are designed for performance-driven individuals. With a 7-layer comfort system and an oil and slip-resistant outsole, these boots offer the comfort and style for the saddle and beyond.</p>
              
              <h4>Durango&reg; Maverick XP&trade;</h4>
              <p>The <a href="https://www.durangoboots.com/durango/mens/by-collection/maverick-xp/" title="Durango Maverick XP">Durango&reg; Maverick XP<sup>&trade;</sup></a> collection offers premium western work boots with state of the art technology that offers all day comfort and support for dynamic work-driven individuals.</p>
              
              <h4>Give Cancer the Boot</h4>
              <p>Durango&reg; continues its ongoing support of the <a href="https://cancer.osu.edu/locations/stefanie-spielman-comprehensive-breast-center" title="Stefanie Spielman Fund">Stefanie Spielman Fund</a> for Breast Cancer Research at The Ohio State University Comprehensive Cancer Center - Arthur G. James Cancer Hospital and Richard J. Solove Research Institute (OSUCCC - James). To date, Durango&reg; has donated over a quarter of a million dollars to breast cancer research!</p>
            </div>
        </div>
        <div className={brandBodyStyles.strategy}>
          <h4>Durango Boots Strategy</h4>
          <p>Durango&reg; Boots are industry leading boots built on traditional silhouettes that are paired with the latest innovations in comfort technology that hold up to whatever you put them through.</p>
          <div className={brandBodyStyles.strategyButtons}>
            <Link to="/customer-resources/join-our-family-of-authorized-dealers/" title="Become a Dealer" className="CTAblack">
              Become a Dealer
            </Link>
            <Link to="/customer-resources/for-our-consumers/" title="Customer Resources" className="CTAalt">
              Customer Resources
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BrandBody
